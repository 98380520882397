/* landing.css */
#landing {
    background-color: rgba(0, 0, 0); 
    background-image:url('../../assets/background_main.webp');
    background-size: cover;
    background-position: center;
    height: 85vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* #landing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  align-items: center;
  min-height: 85vh; 
} */

.content {
  width: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white; /* White text color */
  font-family: Arial, sans-serif; /* Adjust font family */
}
#land_content{
  animation: showDown 2s ease-in-out 1 forwards;
}

#land_img{
  width: 100%;
  height: 85vh;
  animation: showUp 2s ease-in-out 1 forwards;
}

/* Animations */
.content h2,
.content h1 {
  animation: text-reveal 2s 2s ease-in-out forwards;
}

.content h3 {
  animation: text-fade-up 2s 2s ease-in-out forwards;
}

@media (max-width : 770px) {
  #landing{
    flex-direction: row-reverse;
    height: 90vh;
  }
  .content{
    width: 100%;
  }
  #land_img{
    height: 45vh;
    animation: textDown 2s ease-in-out 1 forwards;
  }
  #land_content{
    animation: textup 2s ease-in-out 1 forwards;
    height: 45vh;
  }
}
@media (max-height : 520px) {
  #landing{
    height: auto;
  }
  #land_img{
    height: auto;
    animation: textDown 2s ease-in-out 1 forwards;
  }
  #land_content{
    animation: textup 2s ease-in-out 1 forwards;
    height: fit-content;
  }
}
/* Animation Keyframes */
@keyframes text-reveal {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes text-fade-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showUp {
  0%{
    rotate: 0deg;
    opacity: 0;
  }
  /* 25%{
    rotate: 120deg;
  }
  50%{
    rotate: 240deg;
  } */
  100%{
    rotate: 360deg;
    opacity: 1;
  }
}

@keyframes showDown {
  0%{
    rotate: 360deg;
    opacity: 0;
  }
  /* 25%{
    rotate: 120deg;
  }
  50%{
    rotate: 240deg;
  } */
  100%{
    rotate: 0deg;
    opacity: 1;
  }
}
@keyframes textup {
  0%{
    transform: translateY(-150%);
    opacity: 0;
  }
  100%{
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes textDown {
  0%{
    transform: translateY(150%);
    opacity: 0;
  }
  100%{
    transform: translateY(0%);
    opacity: 1;
  }
}