/* contact.css */
#contact {
    margin-top: 20px;

    background-image: url('../../assets/contact/contact-background.jpg');
}
#cont-icons{
    margin-left: 2%;
}
.cont-title{
    color: white !important;
    margin-bottom: 20px;
}
#contact-content{   
    display: flex;
}
#contact-content > div{
    display: flex;
    width: 50%;
}
#contact-content > div:first-child{
    margin: 10% 0px;
}
.cont-card-img-div{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}
.cont-card-img-div > h3{
    margin-left: 2%;
}

.cont-card-img{
    width: 100% !important;
    border-radius: 50%;
    max-width: 100px;
    max-height: 100px;
}


.contact-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 3% auto;
    width: 80%;
}


.slider-btn {
    border: none;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
}



.btn-pink {
    background-color: #3f3c3c;
    width: fit-content; 
    color: white;
    padding: 0.8rem 2.3rem;
    box-shadow: 5px 5px 7px 0px #0000003f;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5s;
    font-weight: 500;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    margin-top: 3%;
  }
  .btn-pink::before {
    content: "";
    position: absolute;
    background-color: #1f1f1f;
    top: 0;
    left : 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.8s;
  }
  .btn-pink:hover::before {
    transform: scaleX(1);
  }
  .btn-pink:hover {
    color: #fff;
  }
@media (max-width : 1023px) {
    #contact-content{
        flex-direction: column;
    }
    #contact-content > div{
        width: 100%;
    }
}

@media (max-width : 870px) {
    .cont-card-body>div{
        width: 100% !important;
    }
}