/* contact.css */


.footer-card {
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 1% auto;
    width: 90%;
    color: white;
}
.foot-card-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    bottom: 0;
    width: 100%;
}

.footer-text {
    margin: 0;
    font-size: 14px;
    text-align: center;
}

.footer-text a {
    color: #fff;
}

.footer-text a:hover {
    text-decoration: underline;
}

@media (max-width : 870px) {
    .cont-card-body div{
        width: 100% !important;
    }
}